import { Alert } from "@mui/material"
import FeatureSection from "../FeatureSection";
import { useContext, useEffect, useState } from "react";
import { OrgClientInContext } from '../../index'
import { DataGrid, GridColDef } from '@mui/x-data-grid';

function DeploymentTable(props: { deployments: Array<any> }) {
    const columns: GridColDef<(typeof props.deployments)[number]>[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 100
        },
        {
            field: 'deploymentName',
            headerName: 'Name',
            valueGetter: (params) => params.row.configuration.name,
            width: 250
        },
        {
            field: 'mapUUID',
            headerName: 'Map UUID',
            valueGetter: (params) => params.row.configuration.mapUuid,
            width: 100
        },
        {
            field: 'type',
            headerName: 'Type',
            valueGetter: (params) => formatUglyEnumNameToPretty(params.row.configuration.type),
            minWidth: 150
        },
        {
            field: 'binaryObjectCount',
            headerName: 'Binary Object Count',
            valueGetter: (params) => params.row.metadata?.objectCount?.binary,
            width: 150
        },
        {
            field: 'textObjectCount',
            headerName: 'Text Object Count',
            valueGetter: (params) => params.row.metadata?.objectCount?.nonBinary,
            width: 150
        },
        {
            field: 'totalObjectCount',
            headerName: 'Total Object Count',
            valueGetter: (params) => params.row.metadata?.objectCount?.total,
            width: 150
        },
        {
            field: 'locales',
            headerName: 'Locales',
            valueGetter: (params) => params.row.configuration?.locales.join(", "),
            width: 150
        }
    ];

    return (
        <DataGrid
          rows={props.deployments}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 25, 50]}
        />
    )
}

/**
 * Makes an enum name more readable
 * @param uglyEnumName ugly like "AN_UGLY_NAME"
 * @returns a pretty name like "An Ugly Name"
 */
function formatUglyEnumNameToPretty(uglyEnumName: String): String {
    return uglyEnumName.replaceAll("_", " ")
        .toLowerCase()
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
}

export default function ContentDeployment() {
    const client = useContext(OrgClientInContext)

    const [deployments, setDeployments] = useState<any | undefined>(undefined)

    useEffect(() => {
        client.contentApi.getDeployments().then(setDeployments)
    }, [])

    let placeHolderSkeleton =
        <FeatureSection
            featureName=""
            featureDescription=""
            content={""}
        />

    return (
        deployments == undefined ? placeHolderSkeleton :
            <FeatureSection
                featureName="Content Deployments"
                featureDescription=" "
                content={deployments.ezdError == null ? <DeploymentTable deployments={deployments.deployments} /> : <Alert severity="warning">{deployments.message}</Alert>}
            />
    )
}